import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import './Contact.scss';
import SchemaOrg from '../../components/schema.org/SchemaOrg';
function Contact() {
    return (
        <>
            <SchemaOrg />
            <Helmet>
                <title>Contact Us- HireCabs | Contact hireabs  | help on hirecabs
                </title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

            </Helmet>
            <main className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ul className="breadcrumbs">
                                <li className="breadcrumbs__item"><Link to={'/'}>Home</Link></li>
                                <li className="breadcrumbs__item breadcrumbs__item--active">Contact Us</li>
                            </ul>
                        </div>

                        <div className="col-12 col-xl-11">
                            <div className="main__title main__title--page contact_us">
                                <h1 className='headetitle'>Contact Us</h1>
                                <p>We'd love to hear from you! Feel free to reach out using the details below. </p>

                                <br />
                                <div className='m-30'><span><box-icon name='map'></box-icon> </span>  Company Registration Address:
                                    <li>NO. 42, 1ST FLOOR, maduvana building, timmarayappa badavane, Tataguni Village Bengaluru - 560082 (KUPPAREDDY KERE)</li>
                                </div>


                                <br />
                                <div className='m-30'><span><box-icon name='map'></box-icon> </span> Office Address:
                                    <li>No 1747, 1st Floor, Begur Main Road, 3rd Cross, 3rd Block, Vishnuvardhan Statue Road, Bommanahalli, Bangalore - 560068 (Vishwapriya Layout)</li>
                                </div>

                                <div className='m-30'><span><box-icon name='phone'></box-icon> </span> Phone:
                                    <li> +919980207107, +919972257154</li> </div>

                                <div className='m-30'><span><box-icon name='envelope' ></box-icon></span>  Email:
                                    <li>support@hirecabs.in</li></div>

                            </div>
                        </div>

                    </div>
                </div>
            </main >
        </>
    )
}

export default Contact