import React, { useState, useEffect } from 'react'
import { Outlet, Link } from "react-router-dom";
import './Header.scss'
import logoSvg from '../../assets/images/logo.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { popupValue } from '../../services/reducerslice';
import { loggedInStatusSet } from '../../services/loggedinslice';

function Header() {

    const navigate = useNavigate();
    const [showLoginModal, setshowLoginModal] = useState(false);
    const [loggedIn, setLogin] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mobileheader, setMobileHeader] = useState(false);
    const dispatch = useDispatch();
    const popupState = useSelector(state => state.loginPopup.value);
    const checkLoginStatus = useSelector(state => state.loggedIn.value)
    const hideMenu = (e) => {
        e.target.parentElement.parentElement.click();
    }


    const checkLogin = () => {
        let token = localStorage.getItem('token');
        if (checkLoginStatus || token !== null) {
            setLogin(true)
        } else {
            setLogin(false);
        }

    }

    const mobileMenu = () => {
        setMobileHeader(prevState => !prevState);
    }

    const loginPopupOpen = () => {
        dispatch(popupValue(true));
        return;
    }

    const logout = (e) => {
        localStorage.clear();
        dispatch(loggedInStatusSet(false));
        navigate('/')
        window.location.reload();
    }

    const handleBodyClick = (event) => {
        if (event.target.closest('.header__btn')) {
            return;
        }

        if (mobileheader) {
            setMobileHeader(false);
        }
    }

    document.body.addEventListener('click', handleBodyClick);

    useEffect(() => {
        checkLogin();
    }, [checkLoginStatus]);


    useEffect(() => {
        const handleBodyClick = (event) => {
            if (event.target.closest('.header__btn') || event.target.closest('.header__menu')) {
                return;
            }
            if (mobileheader) {
                setMobileHeader(false);
            }
        };

        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [mobileheader]);


    return (
        <>
            <header className="header  header--page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="header__content">
                                <div className="header__logo">
                                    <Link to={`/`} >
                                        <img src={logoSvg} alt="hirecabs logo" />
                                    </Link>
                                </div>

                                <div className={"header__menu " + (mobileheader ? 'header__menu--active' : 'hidden')}>
                                    <ul className="header__nav">
                                        {mobileheader}
                                        <li className="header__nav-item">
                                            <Link to={`/`} className="header__nav-link">Home</Link>
                                        </li>
                                        {/* <li className="header__nav-item">
                                            <Link to={`/cars`} className="header__nav-link">Explore cars</Link>
                                        </li> */}
                                        {/* <li className="header__nav-item">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                    <a className="header__nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">Explore cars <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" /></svg></a>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to={`about`} onClick={hideMenu} ><Dropdown.Item >About </Dropdown.Item></Link>

                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </li> */}
                                        <li className="header__nav-item">
                                            <Link to={`/help`} className="header__nav-link">Help center</Link>
                                        </li>
                                        <li className="header__nav-item">
                                            <Link to={`/contact-us`} className="header__nav-link">Contact us</Link>
                                        </li>
                                        <li className="header__nav-item hidden_desktop">
                                            <Link to={`/add-partner`} className="header__nav-link">Join as Partner</Link>
                                        </li>
                                        {!loggedIn && (
                                            <li className="header__nav-item">
                                                <div className="header__nav-link" onClick={loginPopupOpen}>Login</div>
                                            </li>
                                        )}
                                        {loggedIn && (
                                            <>
                                                <li className="header__nav-item hidden_desktop">
                                                    <Link to={`/myaccount`} className="header__nav-link">My Account</Link>
                                                </li>
                                                <li className="header__nav-item hidden_desktop">
                                                    <div onClick={logout} className="header__nav-link">Logout</div>
                                                </li>
                                            </>
                                        )}
                                        {/* <li className="header__nav-item">
                                            <box-icon size='18px' name='map' type='solid' color='#28608f' ></box-icon> <Link to={`/`} className="header__nav-link">Bangalore</Link>

                                        </li> */}
                                        {/* <li className="header__nav-item hide-for-mobile">
                                            <box-icon size='18px' name='map' type='regular' color='#28608f' ></box-icon> &nbsp;
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                    <a className="header__nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">Bengaluru <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" /></svg></a>
                                                </Dropdown.Toggle>
                                            </Dropdown>

                                        </li> */}
                                        {/* <li className="header__nav-item">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                    <a className="header__nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false"> Pages <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" /></svg></a>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to={`about`}  ><Dropdown.Item >About </Dropdown.Item></Link>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                        <li className="header__nav-item">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                    <a className="header__nav-link header__nav-link--menu" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10ZM5,10a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm14,0a2,2,0,1,0,2,2A2,2,0,0,0,19,10Z" /></svg></a>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Link to={`about`} onClick={hideMenu} >
                                                        <Dropdown.Item >Sign in </Dropdown.Item>
                                                    </Link>
                                                    <Link to={`about`} onClick={hideMenu} >
                                                        <Dropdown.Item >Sign up </Dropdown.Item>
                                                    </Link>
                                                    <Link to={`about`} onClick={hideMenu} >
                                                        <Dropdown.Item >Forgot password </Dropdown.Item>
                                                    </Link>
                                                    <Link to={`about`} onClick={hideMenu} >
                                                        <Dropdown.Item >Privacy policy </Dropdown.Item>
                                                    </Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <ul className="dropdown-menu header__nav-menu">
                                                    <li><a href="signin.html">Sign in</a></li>
                                                    <li><a href="signup.html">Sign up</a></li>
                                                    <li><a href="forgot.html">Forgot password</a></li>
                                                    <li><a href="404.html">404 Page</a></li>
                                                    <li><a href="privacy.html">Privacy policy</a></li>
                                                </ul>
                                        </li> */}
                                    </ul>
                                </div>

                                <div className="header__actions">
                                    <div className="header__phone">
                                        <a href="tel:+919972257154">997 225 7154</a>
                                        <span> Bookings | Support | Help </span>
                                    </div>


                                    {/* <div className="header__actions hidden-desktop location">
                                        <box-icon size='18px' name='map' type='regular' color='#28608f' ></box-icon> &nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                <a className="header__nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">Bengaluru <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z" /></svg></a>
                                            </Dropdown.Toggle>
                                        </Dropdown>

                                    </div> */}

                                    <div className="header__action hidden">
                                        <Link to={`/add-partner`} className='header__action-btn'>
                                            <span>Join as Partner</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.62,13.08a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33A.84.84,0,0,0,8,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,6.62,13.08Zm13.14-4L18.4,5.05a3,3,0,0,0-2.84-2H8.44A3,3,0,0,0,5.6,5.05L4.24,9.11A3,3,0,0,0,2,12v4a3,3,0,0,0,2,2.82V20a1,1,0,0,0,2,0V19H18v1a1,1,0,0,0,2,0V18.82A3,3,0,0,0,22,16V12A3,3,0,0,0,19.76,9.11ZM7.49,5.68A1,1,0,0,1,8.44,5h7.12a1,1,0,0,1,1,.68L17.61,9H6.39ZM20,16a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H19a1,1,0,0,1,1,1Zm-3.38-2.92a.9.9,0,0,0-.54.54,1,1,0,0,0,1.3,1.3.9.9,0,0,0,.54-.54A.84.84,0,0,0,18,14a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,16.62,13.08ZM13,13H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" /></svg>
                                        </Link>
                                    </div>

                                    <div className="header__action hide-for-mobile">

                                        <Dropdown >
                                            <Dropdown.Toggle variant="success" className='header__nav-link'>
                                                <a className={`header__profile-btn ${loggedIn ? "logged-in" : "logged-out"}`} role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={!loggedIn ? loginPopupOpen : undefined}>
                                                    {!loggedIn ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                            <path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" />
                                                        </svg>
                                                    ) : (
                                                        <svg width="232px" height="232px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg">

                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                                                            <g id="SVGRepo_iconCarrier"> <circle cx="9" cy="7" r="3" stroke="#28608f" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" /> <path d="M11 13H7C4.79086 13 3 14.7909 3 17C3 18.6569 4.34315 20 6 20H12C13.6569 20 15 18.6569 15 17C15 14.7909 13.2091 13 11 13Z" stroke="#28608f" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" /> <path d="M20.7249 9.25L15.7751 9.25M15.7751 9.25L17.5 7.5M15.7751 9.25L17.5 10.9749" stroke="#28608f" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" /> </g>

                                                        </svg>
                                                    )}
                                                </a>
                                            </Dropdown.Toggle>
                                            {loggedIn && (
                                                <Dropdown.Menu>
                                                    <Link to={`myaccount`} onClick={hideMenu} >
                                                        <Dropdown.Item >    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" /></svg> <span>My account</span></Dropdown.Item>
                                                    </Link>

                                                    <div className="logoutclass" onClick={logout} >

                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z" /></svg> <span>Sign out</span>

                                                    </div>

                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>



                                        <ul className="dropdown-menu header__profile-menu">
                                            <li><a><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z" /></svg> <span>My account</span></a></li>
                                            <li><a ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9,12H7a1,1,0,0,0,0,2H9a1,1,0,0,0,0-2ZM8,10h4a1,1,0,0,0,0-2H8a1,1,0,0,0,0,2Zm1,6H7a1,1,0,0,0,0,2H9a1,1,0,0,0,0-2Zm12-4H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-6.44-2.83a.76.76,0,0,0-.18-.09.6.6,0,0,0-.19-.06,1,1,0,0,0-.9.27A1.05,1.05,0,0,0,12,17a1,1,0,0,0,.07.38,1.19,1.19,0,0,0,.22.33,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21A1,1,0,0,0,14,17a1.05,1.05,0,0,0-.29-.71A1.58,1.58,0,0,0,13.56,16.17Zm.14-3.88a1,1,0,0,0-1.62.33A1,1,0,0,0,13,14a1,1,0,0,0,1-1,1,1,0,0,0-.08-.38A.91.91,0,0,0,13.7,12.29Z" /></svg> <span>Billing</span></a></li>
                                            <li><a onClick={() => {
                                                dispatch(popupValue(true));
                                            }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z" /></svg> <span>Settings</span></a></li>
                                            <li><a href="#"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z" /></svg> <span>Sign out</span></a></li>
                                        </ul>
                                    </div>
                                </div>

                                <button className="header__btn" type="button" onClick={mobileMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </>
    )

}

export default Header